<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <!-- 标题 -->
      <div class="secondTitle">商品订单</div>
      <!-- 订单筛选栏 -->
      <div class="filter-wrap">
        <div class="status-wrap">
          <div v-for="(item, index) in statuses" :key="index">
            <div
              :class="['status', { active: index == statusIndex }]"
              @click="switchStatus(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="">
          <el-input
            type="text"
            placeholder="输入商品名称、订单号"
            v-model="keyword"
            maxlength="20"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="findOrders(true)"
            ></el-button>
          </el-input>
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="order-wrap" v-loading="loading">
        <div v-for="(item, index) in orders" :key="index">
          <div
            :class="[
              'order',
              { warning: warningStatus.indexOf(item.status) > -1 },
              { green: greenStatus.indexOf(item.status) > -1 },
            ]"
          >
            <div class="header-wrap">
              <div class="top">
                <!-- <div class="status">{{ item.statusDesc }}</div> -->
                <div class="superscript">
                  <div class="gradual"></div>
                  <div class="desc">{{ item.statusDesc }}</div>
                </div>
                <!-- <div class="btn-delete"
                   v-if="item.status == 7 || item.status == 0 || item.status == 3" 
                  @click="deleteOrder(item.id, index)"
                > -->
                <div class="btn-delete" @click="deleteOrder(item.id, index)"
                >

                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <div class="bottom">
                <div class="left">
                  <!-- <div class="detail">{{ item.createTime | filterTime }}</div>
                  <div v-if="item.consignee" class="separator">|</div>
                  <div v-if="item.consignee" class="detail">
                    {{ item.consignee }}
                  </div>
                  <div class="separator">|</div> -->
                  <div class="detail">
                    订单号：
                    <router-link
                      class="orderno"
                      :to="`/order/detail?orderNo=${item.id}`"
                      >{{ item.orderNo }}</router-link
                    >
                  </div>
                  <div v-if="item.payMode" class="separator">|</div>
                  <div v-if="item.payMode" class="detail">
                    {{ item.payMode }}
                  </div>
                </div>
                <div class="right" v-if="item.payAmount > 0">
                  <span class="desc">云淘豆：</span>
                  <span class="amount">{{ item.payAmount.toFixed(2) }}</span>
                  <span class="desc">{{ $currency }}</span>
                  <span class="desc">共{{ sumNumber(item.goodsList) }}件</span>
                </div>
              </div>
            </div>
            <div class="main-wrap">
              <div class="left">
                <div
                  class="goods"
                  v-for="(goods, goodsIndex) in item.goodsList.slice(0,3)"
                  :key="goodsIndex"
                >
                  <a
                    :href="`#/goods/info?productID=${goods.GoodsId}`"
                    target="_blank"
                  >
                    <img
                      class="thumb"
                      src="@/assets/imgs/imgError.jpeg"
                      v-real-img="goods.Photo"
                      mode="aspectFit"
                    />
                  </a>

                  <div class="detail" :title="goods.Name">
                    <a
                      class="name elip"
                      :href="`#/goods/info?productID=${goods.GoodsId}`"
                      target="_blank"
                    >
                      <span
                        :class="[
                          'product-tag',
                          { isjd: goods.GoodsFromType == 1 },
                        ]"
                        >{{ goods.GoodsFromType == 0 ? "严选" : "智选" }}</span
                      >
                      {{ goods.Name }}<br /><span :title="goods.SkuName">{{
                        goods.SkuName
                      }}</span>
                    </a>
                    <!-- <div class="more">
                      {{ goods.Price + "" + $currency }} x {{ goods.Numbers }}
                    </div> -->
                  </div>
                </div>
              </div>

              <!-- <div class>
                <span>已发货</span>
              </div> -->
              <div class="right">
                <el-row>
                  <!-- <el-col :span="8"><span>运费：10.00</span></el-col> -->
                  <el-col>
                    <div style="text-align: center">
                      <!-- <router-link
                      v-if="item.status == 0"
                      class="btn btn-primary"
                      :to="`/order/pay?orderNo=${item.id}`"
                      >立即兑换</router-link
                    > -->
                      <a
                        v-if="item.status == 0"
                        class="btn btn-primary btn-a"
                        @click="dialogPay(item)"
                      >
                        立即兑换
                      </a>
                      <!-- <a
                        v-if="item.status == 1"
                        class="btn btn-primary"
                        @click="confirmReceipt(item)"
                      >
                        申请退款
                      </a>
                      <a
                        v-if="item.status == 2"
                        class="btn btn-primary"
                        @click="confirmReceipt(item)"
                      >
                        申请退货
                      </a> -->
                      <!-- <a
                        v-if="item.status == 4 || item.status == 9"
                        class="btn btn-primary btn-a"
                        @click="confirmReceipt(item)"
                      >
                        取消
                      </a> -->
                      <a
                        v-if="item.status == 2"
                        class="btn btn-primary btn-a"
                        @click="confirmReceipt(item, index)"
                      >
                        确认收货
                      </a>
                      <router-link
                        class="btn btn-a"
                        :to="`/order/detail?orderNo=${item.id}`"
                        >订单详情</router-link
                      >
                    </div>
                    <!-- <a v-if="item.status == 20" class="btn">申请售后</a> -->
                    <div
                      v-if="item.status != 7"
                      class="btn"
                      @click="hrefTencent"
                    >
                      联系客服
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <!-- 订单商品大于三条隐藏多余数据 -->
            <router-link
              v-if="item.goodsList.length > 3"
              class="more"
              :to="`/order/detail?orderNo=${item.id}`"
              >查看更多></router-link
            >
          </div>
        </div>

        <!-- 分页条 -->
        <el-pagination
          v-if="orders.length > 0"
          :pager-count="11"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          :current-page="pageNumber"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
          @current-change="pageChange"
          @size-change="handleSizeChange"
        >
        </el-pagination>
        <!-- 没有数据提示 -->
        <el-empty
          v-else-if="!loading"
          :image-size="200"
          description="暂未查询到相关订单记录。"
        ></el-empty>
      </div>
    </menu-wrapper>
    <payIndex :visible.sync="visible" :id="clickOrderId"></payIndex>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";
import payIndex from "@/views/order/pay/index";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    menuWrapper,
    payIndex,
  },
  data() {
    return {
      statuses: [
        "全部",
        "待兑换",
        "待发货",
        "待收货",
        "已收货",
        "已取消",
        "待审核",
      ], //
      statusIndexs: [-1, 0, 1, 2, 3, 7, 11],
      statusIndex: 0,
      orders: [],
      keyword: "",
      pageNumber: 1,
      totalSize: 0,
      loading: true,
      pageSize: 10,
      pageSizes: [10, 50, 100, 200],
      greenStatus: [2, 3, 5, 9],
      warningStatus: [0, 1, 4, 6, 8, 10, 11],
      visible: false,
      clickOrderId: "",
    };
  },
  filters: {
    filterTime(datetime) {
      const units = ["-", "-", ""];
      datetime = datetime.split(" ");
      return (
        datetime[0]
          .split("-")
          .map((e, i) => e + units[i])
          .join("") +
        " " +
        datetime[1].substring(0, 8)
      );
    },
  },
  computed: {
    sumNumber() {
      return (data) => {
        let number = 0;
        data.forEach((item) => {
          number += item.Numbers;
        });
        return number;
      };
    },
  },
  activated() {
    if (this.$route.query.status) {
      this.statusIndex = this.$route.query.status;
    }
    this.findOrders();
  },
  methods: {
    ...mapGetters(["getUser", "getUserId"]),
    ...mapActions(["setOrderInfo"]),
    //支付确认弹窗
    dialogPay(data) {
      this.visible = true;
      const createTime = data.createTime.replace("T", " ");
      const endTime = this.$util.getOrderEndTime(createTime);
      const mode = {
        createTime: createTime,
        payAmount: data.payAmount,
        id: data.id,
        endTime: endTime,
        orderNo: data.orderNo,
        status: data.status,
        isDelte: false,
      };
      this.setOrderInfo(mode);
      this.clickOrderId = data.id.toString();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNumber = 1;
      this.findOrders();
    },
    findOrders(isSwitchPage = false) {
      this.orders = [];
      this.loading = true;
      if (isSwitchPage) {
        this.pageNumber = 1;
      }
      this.$axios
        .get("/api/GoodsOrder/OrderList?OrderBy=id desc", {
          params: {
            PageSize: this.pageSize,
            PageIndex: this.pageNumber,
            UserID: this.getUserId(),
            OrderStatus: this.statusIndexs[this.statusIndex],
            where: this.keyword,
          },
        })
        .then((res) => {
          if (res.data) {
            const data = res.data;
            res.data.forEach((item) => {
              const payMode = "";
              const status = item.OrderStatus;
              const statusDesc = this.orderStatusText(status);
              let createTime;
              if (item.modify_date) {
                createTime = item.modify_date;
              } else {
                createTime = item.CreateTime;
              }
              createTime = createTime.replace("T", " ");
              const element = {
                status: status,
                statusDesc: statusDesc,
                createTime: createTime,
                id: item.Id,
                orderNo: item.OrderId,
                payAmount: item.Money,
                payMode: payMode,
                goodsList: item.GoodsOrders,
              };
              this.orders.push(element);
            });
            this.totalSize = res.itemCount;
            this.loading = false;
          }
        });
    },
    switchStatus(index) {
      this.statusIndex = index;
      this.findOrders(true);
    },
    confirmReceipt(item, index) {
      this.$alert("确定已收货吗？", "", {
        distinguishCancelAndClose: true,
        showCancelButton: true,
        cancelButtonText: "取消",
        closeOnClickModal: true,
      })
        .then(() => {
          this.loading = true;
          this.$axios
            .put(
              "/api/GoodsOrder/DeliveryGood",
              this.qs.stringify({ OrderID: item.id })
            )
            .then((res) => {
              if (res.data) {
                this.orders.splice(index, 1);
                this.$notifySucceed("已确认收货");
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch((action) => {
          console.log(action, "点击取消");
        });
    },
    deleteOrder(id, index) {
      this.$alert(
        "删除后，您的订单将无法查看或还原和售后。确定删除该订单吗？",
        "温馨提示：",
        {
          closeOnClickModal: true,
        }
      ).then(() => {
        this.$axios
          .put("/api/GoodsOrder/DeleteOrder?OrderID=" + id, "")
          .then((res) => {
            if (res.data) {
              this.orders.splice(index, 1);
            }
          });
      });
    },
    pageChange(e) {
      this.pageNumber = e;
      this.findOrders();
    },
  },
};
</script>

<style lang="scss">
// .title {
//   font-size: 30px;
//   color: #757575;
//   line-height: 68px;
// }

.filter-wrap {
  padding: 0 0 16px 0;
  @extend %flex-align-center;
  justify-content: space-between;

  .status-wrap {
    @extend %flex-align-center;

    .status {
      font-size: 16px;
      line-height: 1.25;
      padding: 0 20px;
      border-left: 1px solid #e0e0e0;
      color: #757575;
      cursor: pointer;

      &:first-child {
        border-left: none;
        padding-left: 0;
      }

      &:hover {
        color: $text-color;
      }

      &.active {
        color: $color-primary;
      }
    }
  }
}

.order-wrap {
  min-height: 200px;
  .more {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9093b3;
    margin: 10px 0px;
    &:hover{
      color: $color-primary;
    }
  }
  .order {
    border: 1px solid #b0b0b0;
    margin-bottom: 20px;

    &.warning {
      border-color: $color-primary;

      .header-wrap {
        background-color: rgba($color-primary, 0.1);

        .status {
          color: $color-primary;
        }
        .top {
          .superscript {
            .gradual {
              background: linear-gradient(135deg, #f2270c 50%, transparent 50%);
            }
            .desc {
              color: #ffffff;
            }
          }
        }
      }
    }

    &.green {
      border-color: $color-success;

      .header-wrap {
        background-color: rgba($color-success, 0.1);

        .status {
          color: $color-success;
        }
        .top {
          .superscript {
            .gradual {
              background: linear-gradient(135deg, #67c23a 50%, transparent 50%);
            }
            .desc {
              color: #ffffff;
            }
          }
        }
      }
    }

    .header-wrap {
      border-bottom: 1px solid $border-color;

      .top {
        @extend %flex-align-center;
        justify-content: space-between;
        height: 20px;
        .superscript {
          // position: absolute;
          top: 0px;
          left: 0px;
          width: 55px;
          height: 55px;
          overflow: hidden;
          margin-top: 35px;
          /* 使用背景色渐变 */
          .gradual {
            width: 100%;
            height: 100%;
            background: linear-gradient(135deg, #b0b0b080 50%, transparent 50%);
          }

          .desc {
            position: absolute;
            // top: 10%;
            //   left: 20%;
            z-index: 1;
            color: #303133;
            transform: rotate(-45deg);
            font-size: 10px;
            margin-top: -45px;
          }
        }

        .btn-delete {
          margin-top: 15px;
          margin-right: -15px;
          cursor: pointer;
          padding: 0px 30px;
          .i-delete {
            font-size: 24px;
            color: #303133;
            opacity: 0;
          }
        }
      }

      .bottom {
        @extend %flex-align-center;
        justify-content: space-between;
        padding: 0px 30px;
        min-height: 37px;
        .left {
          color: #757575;
          max-width: 600px;
          @extend %flex-align-center;

          .separator {
            color: $border-color;
            margin: 0 0.5rem;
          }

          .orderno {
            display: inline-div;

            &:hover {
              color: $color-primary;
            }
          }
        }

        .right {
          .desc {
            color: #757575;
          }

          .amount {
            font-size: 28px;
            color: $text-color;
            margin-right: 5px;
          }
        }
      }
    }

    .main-wrap {
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      padding-bottom: 0;

      .left {
        padding: 10px 0;
        max-height: 252px;
        overflow: hidden;
        .goods {
          @extend %flex-align-center;

          .thumb {
            width: 80px;
            height: 80px;
          }

          .detail {
            margin-left: 20px;
            color: $text-color;
            line-height: 18px;
            width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .name {
              color: $text-color;
              display: inline-div;
              cursor: pointer;
              // max-width: 500px;

              &:hover {
                color: $color-primary;
              }
              span {
                // font-size: 12px;
                color: #909399;
                margin-left: 10px;
              }
              .product-tag {
                border-radius: 10px;
                background-color: #67c23a;
                color: #f5f5f5;
                padding: 0px 10px;
                font-size: 12px;
                &.isjd {
                  background-color: #ff5933;
                }
              }
            }

            .more {
              font-size: 13px;
            }
          }
        }
      }

      .right {
        /*弹性盒模型*/
        display: flex;
        /*主轴居中对齐*/
        justify-content: center;
        /*侧轴居中对齐*/
        align-items: center;
        .btn {
          border: 1px solid #b0b0b0;
          background-color: $bg-color;
          color: #757575;
          width: 130px;
          height: 28px;
          font-size: 13px;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s;
          margin-bottom: 10px;
          // padding-right: 10px;
          &.btn-primary {
            background-color: $color-primary;
            color: #ffffff;
            border-color: $color-primary;

            &:hover {
              background-color: $color-primary;
              color: #ffffff;
              border-color: $color-primary;
              opacity: 0.9;
            }
          }

          &:hover {
            color: #fff;
            background-color: #757575;
            border-color: #757575;
          }
          &.btn-a {
            padding: 2px 5px;
            border-radius: 3px;
            margin-right: 3px;
          }
        }
      }
    }

    &:hover {
      .header-wrap .top .btn-delete .icon {
        opacity: 1;
      }
    }
  }
}
</style>
